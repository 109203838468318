<template>
    <div class="contactUs">
        <div class="title">
            <img src="../assets/images/contact-title-bg.png" alt="">
        </div>
        <div class="contactUs-box">
            <div class="contactUs-info">
              <p class="group-number">微信搜索加入福利群:  xft9310</p>
              <p class="email">商务合作邮箱:  hexin@apass.cn</p>
              <img class="contact-qrcode" src="../assets/images/contact-qrcode.png" alt="">
              <p class="more-welfare">更多福利</p>
              <p class="scan-text">请微信扫描二维码关注我们</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style>
    .contactUs{
        background-color: #232323;
        padding-top: 88px;
        padding-bottom: 80px;
        height: 100vh;
    }
    .contactUs-box{
        margin: 0 auto;
        width: 1340px;
        height: 778px;
        background: url('../assets/images/contact_bg.png') no-repeat;   
    }
    .contactUs-info{
        padding-top: 97px;
        text-align: center;
    }
    .contactUs-info p{
        font-size: 20px;
        color: #F3EDE2;
    }
    .contactUs-info .email{
        margin-top: 32px;
    }
    .contact-qrcode{
        margin-top: 30px;
        margin-bottom: 25px;
        width: 380px;
        height: 380px;
    }
    .contactUs-info .scan-text{
        margin-top: 10px;
        font-size: 16px;
    }
</style>
